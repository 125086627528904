/* Ensure correct layout with sidebar */
.content-area {
    margin-left: 250px;
    /* Adjust based on your sidebar width */
    padding: 20px 30px;
    width: calc(90% - 250px);
    box-sizing: border-box;
    margin-top: 60px;
    /* Add space for the status bar at top */
    background-color: #f9f9f9;
    min-height: calc(100vh - 60px);
}

/* Back button styling */
.back-button {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    background-color: white;
    border-radius: 20px;
    color: #333;
    font-weight: 500;
    text-decoration: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
    margin-bottom: 20px;
    border: none;
}

.back-button:hover {
    background-color: #f0f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
}

/* Navigation container styles */
.navigation-container {
    position: fixed;
    top: 0;
    left: 250px;
    /* Match sidebar width */
    right: 0;
    height: 60px;
    /* Height of status bar */
    z-index: 100;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Fix spacing for empty posts */
.MuiCardHeader-content {
    min-height: 40px;
}

/* Responsive adjustments */
@media (max-width: 992px) {
    .content-area {
        margin-left: 0;
        width: 100%;
        padding: 15px;
        margin-top: 60px;
    }

    .navigation-container {
        left: 0;
    }

    /* Adjust sidebar if needed */
    .side-wrp {
        display: none;
        /* Hide sidebar on mobile or adjust as needed */
    }
}

/* Ensure card contents don't overflow */
.MuiPaper-root {
    max-width: 80%;
    overflow-x: hidden;
}

/* Make images responsive */
img {
    max-width: 80%;
    height: auto;
}

/* Fix for chip display in posts */
.MuiChip-root {
    max-width: 80%;
    margin: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Improve typography responsiveness */
@media (max-width: 600px) {
    .MuiTypography-h4 {
        font-size: 1.5rem;
    }

    .MuiTypography-h6 {
        font-size: 1.1rem;
    }
}