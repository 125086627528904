.navbar>.container.custom-align{
    justify-content: end;
}

.search-bar::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white !important; 
    opacity: 1; /* Firefox */
}

.search-bar:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white !important;
}

.search-bar::-ms-input-placeholder { /* Microsoft Edge */
    color: white !important;
}