/* Main container styling */
.content-area {
    transition: all 0.3s;
    background-color: #f5f5f5;
}

/* Back button hover effect */
.back-button:hover {
    background-color: #e0e0e0 !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    transform: translateY(-2px);
}

/* Table hover effects */
.MuiTableRow-hover:hover {
    background-color: rgba(33, 150, 243, 0.04) !important;
}

/* Chip animation */
.MuiChip-root {
    transition: all 0.3s ease;
}

.MuiChip-root:hover {
    transform: scale(1.05);
}

/* Action buttons */
.MuiButton-outlined {
    transition: all 0.2s ease;
}

.MuiButton-outlined:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}